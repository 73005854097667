import dataapiRequest from '@/utils/dataapiRequest'
import request from '@/utils/request'



//会员中心 购买记录 统一下载接口
export function downloadByOrder(params) {
  return dataapiRequest({
    url: '/frontend/bigdata/download/do',
    method: 'post',
    params,
    loading:true
  })

}

//删除订单
export function deleteByOrder(params) {
  return request({
    url: '/members/delete-order',
    method: 'post',
    params
  })

}





//下单
export function placeOrder(params) {
  return dataapiRequest({
    url: '/frontend/bigdata/wechat-pay/place-order',
    method: 'post',
    params
  })

}

//支付状态查询
export function paystatus(params) {
  return dataapiRequest({
    url: '/frontend/bigdata/wechat-pay/check-pay',
    method: 'post',
    params
  })

}


//下载文件 图片
export function downloadfile(params) {
  return dataapiRequest({
    url: '/frontend/bigdata/file-download/pay-batch',
    method: 'post',
    params,
    loading: true
  })
}


//下载Excel
export function downloadExcel(params) {
  return dataapiRequest({
    url: '/frontend/bigdata/excel-download/down',
    method: 'post',
    params
  })
}


//大数据需要下载文件的列表
export function fileDownList(params) {
  return dataapiRequest({
    url: '/frontend/bigdata/landdata/file-download-list',
    method: 'post',
    params
  })
}

//发票申请
export function applyInvoice(params) {
  return dataapiRequest({
    url: '/frontend/bigdata/order/apply-bill',
    method: 'post',
    params
  })



}