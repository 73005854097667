<template>
	<div class="memebercontainer">

		<div class="membercon">
			<div  v-if="!userinfo.isvip" :class="{ 'vipcard':true,'chongzhicard':!userinfo.isvip,'supercard': userinfo.isvip }"  >
				<div class="cardleft"    >
					<div class="infoleft">
						<img style="height:60px;width:60px;border-radius:50%" :src="userinfo.avatar" />
					</div>
					<div class="inforight">
						<div class="infotop" >
							<div class="vipname">{{ userinfo.truename }}</div>
							<div :class="{chongzhilevel:userinfo.balance,viplevel:userinfo.isvip,}" style="background:#fff">
								<img  v-if="userinfo.isvip"  class="vipv" src="@/assets/vipv.png" alt="">
								<img  v-if="!userinfo.isvip && userinfo.balance !== 0"  class="vipv" src="@/assets/vipv2.png" alt="">
								<div v-if="userinfo.isvip" class="superword">
									超级VIP
								</div>
								<div v-if="!userinfo.isvip && userinfo.balance !== 0 " class="superword" style="color:#49C3F3">
									超值包
								</div>
							</div>
						</div>

						<div v-if="userinfo.isvip" class="vipdate">{{ userinfo.due_date }}</div>
						<div v-if="!userinfo.isvip" class="vipdate">可用余额：{{ userinfo.balance }}元 
							<span v-if="userinfo.is_bigdata_landdata_vip === 1">(VIP会员{{ userinfo.bigdata_landdata_vip_due_time }})</span>
							<span v-if="userinfo.is_bigdata_landdata_vip === 2">(VIP会员已过期)</span>
						</div>
					</div>
				</div>
				<div class="cardright">
					<img src="@/assets/cardlogo.png" alt="">
				</div>
			</div>

			<div class="selectvip">
				<div  @click="changeSelect(1)" :class="{mianfei:true,conbox:true,selected:isselect === 1}" >
					<div class="contop">
						<div class="viptitle">
							<div class="bigtitle">VIP会员</div>
							<div class="smalltitle">适合个人用户</div>
						</div>
						<div class="viplist">
							<div class="itemtitle">享有功能</div>
							<div :class="{vipitem:true,huang:item.is_vip === 1}" v-for="item in viplist"  :key="item.id">{{ item.name }}</div>
						</div>
					</div>


					<div class="jine">
						<span>¥<span class="currentMoney">{{ currentvipMoney }}</span>/年</span>
					</div>
				</div>
				<div @click="changeSelect(2)" :class="{chongzhi:true,conbox:true,selected:isselect === 2}">
					<div class="contop">
						<div class="viptitle chongzhititle">
							<div class="bigtitle ">超值数据包</div>
							<div class="smalltitle">适合个人用户</div>
						</div>

						<div class="viplist">
							<div class="itemtitle">享有功能</div>
							<div  :class="{vipitem:true,huang:item.is_vip === 1}"  v-for="item in recharge" :key="item.id">{{ item.name }}</div>

							<div class="songbox">
								<div class="song">
									<img class="icon" src="@/assets/gift.png" style="height:20px;width:20px">
									<div class="text">赠送一年VIP会员</div>
								</div>
								<div class="songcontent">不限次数查看</div>
							</div>

						</div>
					</div>
					<div class="jine">
						<span class="tips">数据按条收费，多充多惠</span>
					</div>

				</div>
				<div  @click="changeSelect(3)" :class="{chaoji:true,conbox:true,selected:isselect === 3}">
					<div class="contop">
						<div class="viptitle supertitle">
							<div class="bigtitle ">超级VIP版</div>
							<div class="smalltitle">适合企业用户</div>
						</div>

						<div class="viplist">
							<div class="itemtitle">享有功能</div>
							<div  :class="{vipitem:true,huang:item.is_vip === 1}" v-for="item in superlist" :key="item.id">{{ item.name }}</div>
						</div>
					</div>
					<div class="jine">
						<span>¥<span class="currentMoney">{{ currentsuperMoney }}</span>/年</span>
					</div>

				</div>

			</div>
			<div >
				<div class="fangan">
					<div class="lan"></div>
					<div class="lanzi">续费方案</div>
				</div>
				<div class="fanganlist" v-if="isselect === 1">
					<div :class="{ fangitem: true, currentchecked: vipchecked === item.id }" 
						v-for="item in fanganvipList" :key="item.name" @click="changevipselect(item)" >
						<div class="fangname">{{ item.name }}</div>
						<div class="fangjine"><span class="rbmicon">¥</span>{{ item.price }}</div>
					</div>
				</div>
				<div class="fanganlist" v-if="isselect === 3">
					<div :class="{ fangitem: true, currentchecked: item.checked }" 
						v-for="item in fanganlist" :key="item.name">
						<div class="fangname">{{ item.name }}</div>
						<div class="fangjine"><span class="rbmicon">¥</span>{{ item.price }}</div>
					</div>
				</div>
				<div class="fanganlist" v-if="isselect === 2">
					<div :class="{ fangitem: true, currentchecked: item.checked }" @click="changeMoney(item)"
						v-for="item in fanganlist" :key="item.name">
						<div class="fangname">充<span>{{item.recharge_amount}}</span>得<span>{{ item.actual_amount }}</span>	<span v-if="item.limit > 0 " >(限首次)</span></div>
						<div class="fangjine"><span class="rbmicon">¥</span>{{ item.recharge_amount }}
						</div>
					</div>
				</div>
			</div>

			<div class="buybutton"  >
				<download-button
				v-if="isselect === 1"
				 :params="{
					goods_id: 12,
					condition: {
						'bigdata_landdata_vip_id':vipchecked
					}

				}" :text="text" @success="paySuccess" size="normal"></download-button>


				<download-button
				v-if="isselect === 3"
				 :params="{
					goods_id: 7,
					condition: {
						vip_config_id: vipdateID,
					}

				}" :text="text" @success="paySuccess" size="normal"></download-button>

				<download-button 
				v-if="isselect === 2"
				:params="{
					goods_id:11,
					condition: {
						recharge_plan_id: vipdate1ID,
					}

				}" :text="text" @success="paySuccess" size="normal"></download-button>


			</div>
		</div>


	</div>
</template>

<script>

import { logout, vipdate,vipdate1, memberfunctionlist, buyrecodrd, info } from '@/api/member'

import DownloadButton from "@/components/down/DownloadButton.vue"

export default {

	data() {
		return {
			isselect:2,
			text: '立即购买',
			viplist: [],
			recharge:[],
			superlist: [],
			fanganlist: [],
			vipchecked:0,
			fanganvipList:[],
			fanganlist1: [],
			fanganlist2: [],
			currentsuperMoney: 88,
			currentvipMoney: 0,
			currentsuperMoney1:0,

			payMoney: 0, //支付金额
			vipdateID: 0,
			vipdate1ID:0,
			userinfo: {},
			url: ''

		};
	},


	components: {
		DownloadButton
	},

	mounted() {
		if (this.$route.query.url) {

			const url = this.$route.query.url
			this.url = decodeURIComponent(escape(window.atob(url)));
		}

		this.getUserInfo()


		this.getVIPList()
		this.getFangan()

	},


	methods: {
		changevipselect(item){
			 this.$nextTick(()=>{
				this.vipchecked = item.id
			 });
			
		},

		changeSelect(index){
			this.isselect = index
			if(index === 3){
				this.fanganlist = this.fanganlist1
			}
			if(index === 2){
				this.fanganlist = this.fanganlist2
			}

		},

		paySuccess() {
			if (this.url) {
				window.location.href = this.url
			} else {
				window.location.reload()
			}
		},

		getUserInfo() {
			info().then(res => {
				this.userinfo = res.data
				if (res.data.isvip) {
					this.text = '会员续费'
				}
			})
		},

		getVIPList() {
			memberfunctionlist().then(res => {

				this.viplist = res.data.function_list.common
				this.currentvipMoney = res.data.bigdata_vip_activity_price
				this.recharge = res.data.function_list.recharge
				this.superlist = res.data.function_list.vip
				this.fanganvipList = res.data.bigdata_vip_list
				this.vipchecked =  res.data.bigdata_vip_list[0].id
			})

		},

		getFangan() {
			vipdate().then(res => {
				this.fanganlist1 = res.data
				this.currentsuperMoney = res.data[0].price
				res.data[0].checked = true
				this.vipdateID = res.data[0].id

			})

			vipdate1().then(res => {
				this.fanganlist = this.fanganlist2 = res.data
				this.currentsuperMoney1 = res.data[0].recharge_amount
				res.data[0].checked = true
				this.vipdate1ID = res.data[0].id
			})

		},


		changeMoney(data) {
			const newdata = this.fanganlist.map(item => {
				if (item.recharge_amount.toString() === data.recharge_amount.toString()) {
					item.checked = true
					this.payMoney = item.recharge_amount
					this.vipdate1ID = item.id
				} else {
					item.checked = false
				}
				return item

			})
			this.fanganlist = newdata
		}

	},
};
</script>

<style lang="scss" scoped>
.songbox{
	border:solid 2px #FF2B2B;
	border-radius: 10px ;
	.song{
		width: 240px;
		height: 36px;
		background: linear-gradient( 49deg, #FF5353 0%, #FF2B2B 100%);
		display: flex;
		justify-content: center;
		align-items: center;
		.icon{
			width: 30px;
			height: 30px;
		}
		.text{
			margin-left:10px;
			font-size: 18px;
			font-weight: bold;

			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 50px;
			text-align: center;
			font-style: normal;
		
		}
	}
	.songcontent{
		font-size: 18px;
		color:#b97a31;
		line-height: 36px;
	}
}
.huang{
	color:#EBB26F;
}

.vipcard {
	width: 864px;
	height: 100px;
	
	border-radius: 10px;
	margin-bottom: 10px;
	display: flex;

	.cardleft {
		width: 60%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		.infoleft {
			margin-left: 20px;
			margin-right: 10px;

			.img {
				border-radius: 50%;
				border: solid 2px #fff;
			}


		}

		.inforight {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-start;

			.infotop {
				display: flex;
				justify-content: space-around;

				.vipname {
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 28px;
				}

				.chongzhilevel{
					margin-left: 12px;
					width: 111px;
					height: 30px;
					background:  #80DBFF;
					border-radius: 26px;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.vipv {
						height: 30px;
						width: 30px;

					}
					.superword {
						margin-left: 10px;
						height: 22px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #EBB26F;
						line-height: 22px;
					}
				}
				.viplevel {
					margin-left: 12px;
					width: 111px;
					height: 30px;
					background: #333640;
					border-radius: 26px;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.vipv {
						height: 30px;
						width: 30px;

					}



					.superword {
						margin-left: 10px;
						height: 22px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #EBB26F;
						line-height: 22px;
					}
				}
			}

			.vipdate {
				margin-top: 5px;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 20px;
			}

		}
	}

	.cardright {
		width: 40%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 5px;

		img {
			width: 134px;
			height: 48px;
		}
	}
}

.chongzhicard{

	background: linear-gradient(135deg, #80DBFF 0%, #00A3E4 100%);
}
.supercard{



	background: linear-gradient(135deg, #947036 0%, #333640 100%);
}

.fangan {
	margin-top: 20px;
	display: flex;
	align-items: center;

	.lan {
		width: 4px;
		height: 20px;
		background: #00A3E4;
		margin-right: 8px;
	}

	.lanzi {
		width: 72px;
		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 25px;
	}


}

.fanganlist {
	display: flex;
	flex-direction: row;
	margin-top: 20px;

	.fangitem {
		margin-left: 20px;
		cursor: pointer;
		width: 130px;
		height: 76px;
		background: #fff;
		border-radius: 5px;
		border: 1px solid #E4E4E4;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.rbmicon {
			font-size: 12px;
		}

		.fangname {
			height: 30px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 30px;
		}

		.fangjine {
			height: 30px;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 600;
			color: #EB0000;
			line-height: 17px;

		}
	}

	.currentchecked {
		background: #E5F5FC;
	}
}

.currentMoney {
	font-size: 50px;
	font-weight: 600;
}
.tips{
	width: 154px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 20px;
	position: relative;
	bottom:20px;
}

.viplist {
	display: flex;
	flex-direction: column;
	align-items: center;

	.itemtitle {
		margin-top: 20px;
		width: 72px;
		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		line-height: 25px;
	}

	.vipitem {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #5B6980;
		line-height: 34px;

	}
	.huang{
		color: #ffa600;
	}
}

.memebercontainer {

	width: 100%;
	height: auto;
	min-height:800px;
}

.membercon {
	.buybutton {
		margin: 20px;
		display: flex;
	}

	padding: 10px;

	min-height: 400px;
	width: 100%;
	background: #fff;
	margin-top: 10px;
	box-sizing: border-box;

	.selectvip {
		display: flex;

		.conbox {
			cursor: pointer;
			width: 280px;
			min-height: 590px;
			background: #FFFFFF;
			border-radius: 5px;
			border: 1px solid #E4E4E4;
			margin-right: 10px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.contop {}

			.viptitle {
				width: 280px;
				height: 114px;
				background: linear-gradient(135deg, #CAD6EC 0%, #8395B3 100%);
				border-radius: 5px 5px 0px 0px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.bigtitle {
					height: 42px;
					font-size: 30px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 42px;
				}

				.smalltitle {
					width: 108px;
					height: 25px;
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 25px;
				}
			}

			.supertitle {
		
				background: linear-gradient(135deg, #947036 0%, #333640 100%);
				// background:url(require('@/assets/85.png'));
				// -webkit-mask-size: contain;
				// -webkit-mask-image: url(xxx.png);
				// -webkit-mask-repeat: no-repeat;
				// -webkit-mask-position: center top;
				// -webkit-mask-size: 100% 100%;

			}
			.chongzhititle{
				background: linear-gradient(135deg, #80DBFF 0%, #00A3E4 100%);
				
			
			}
		}


		.chaoji {
			border: 1px solid #E4E4E4;
		}
		.chongzhi {
			border: 1px solid #E4E4E4;
		}
		.selected{
			border: 1px solid #00A3E4;
		}


	}

}





.bread {
	width: 100%;
	height: 40px;
	background: #FFFFFF;
	display: flex;
	align-items: center;

	.titlespan {
		width: 56px;
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #232323;
		line-height: 20px;
		margin-left: 20px;
	}



}
</style>