<template>
	<div>
		<el-button :size="size" @click="download" type="primary">{{ text }}</el-button>

		<el-dialog title="" :modal="false"  :visible.sync="dialogPay" width="40%" @close="colsedialog">
			<div class="downtitle">您正在开通土拍网会员功能，此功能需要微信扫码支付</div>
			<div class="downjine">微信支付: <span class="jine">{{ price }}元</span></div>
			<div class="paypic">
				<img :src="qrcode" class="pic" />
			</div>
			<div class="paymark">
				<img class="imgpay" src="@/assets/icon/weixin.png" alt="">
				<span>微信扫码支付</span>
			</div>
			<div class="tongyi">
				<span>支付即同意 <a class="xieyi">《土拍网会员服务协议》</a></span>
			</div>
		</el-dialog>
	</div>
</template>
<script>


import { placeOrder, paystatus, downloadfile, downloadExcel } from '@/api/orderpay.js'
import { ColorPicker } from 'element-ui';
export default {
	name: 'WorkspaceJsonPayDialog',
	props: {
		text: String,
		params: {
			type: Object
		},
		icon: {
			type: String,
			default: 'el-icon-download'
		},
		size: {
			type: String,
			default: 'mini'
		},
		type: {
			type: String,
			default: 'image',//image excel //图片和excel
		}

	},
	computed: {
		userinfo: {
			get() {
				return JSON.parse(this.$store.state.userinfo)
			}
		}
	},

	data() {
		return {
			dialogPay: false,
			dialogDown: false,
			qrcode: '',
			price: 0,
			timer: {},
			order_no: '',//订单号
			t: {}

		};
	},


	methods: {
		colsedialog() {
			window.clearInterval(this.t)
		},
		//服务器下载
		download() {
			const params = this.params
			console.log('params', params)
			// let _this = this;
			// downloadZip(params).then((res) => {
			placeOrder(params).then(res => {
				// console.log('orderpayres', res.data)
				const order = res.data.order
				this.price = res.data.order.price
				this.order_no = res.data.order.order_no

				if (order.price === 0) {
					//下载


				} else {
					//支付//处理地址//开启轮询
					this.getPaystatus(res.data.order.order_no)
					this.qrcode = res.data.qrcode
					this.dialogPay = true
				}

			})



		},


		//获取支付状态 轮询
		getPaystatus(order_no) {
			var that = this
			var once = 1

			this.t = window.setInterval(function () {
				once = once + 1

				paystatus({ order_no: order_no }).then(res => {

					if (res.data.pay_status === 1) {
						//支付成功 结束轮循
						window.clearInterval(that.t)
						// //关闭支付框
						that.dialogPay = false

						that.$store.dispatch('userinfo').then(res => {
							that.$alert(`尊敬的${res.truename},您已经成功充值`, '支付成功', {
								confirmButtonText: '确定',
								type: 'success',
								callback: action => {
									that.$emit('success')
								}
							})

						})




						// logout().then(res => {
						// 	// Cookies.
						// 	that.$store.commit('setToken', '')
						// 	localStorage.setItem('token', '')
						// 	Cookies.remove('token')
						// 	that.$store.commit('userinfo', '')
						// 	Cookies.remove('userinfo')
						// 	that.$router.push('/login')
						// })

					}

				})
				if (once > 60) {
					window.clearInterval(that.t)
					// //关闭支付框
					that.dialogPay = false
				}

			}, 2000);

		}




	},

	destoryed() {
		window.clearInterval(t)
	}

};
</script>

<style lang="scss" scoped>
.downtitle {
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Semibold, PingFang SC;
	font-weight: 600;
	color: #000000;
	line-height: 25px;
}

.downjine {
	height: 33px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
	margin-top: 20px;

	.jine {
		color: rgba(255, 0, 0, 0.805);
		font-weight: 600;
	}

}

.paypic {
	.pic {
		width: 186px;
		height: 186px;
		background: #aaa;
	}
}

.paymark {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	margin-bottom: 5px;

	.imgpay {
		height: 20px;
		width: 20px;
		margin-right: 10px;
	}

	span {
		width: 96px;
		height: 22px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 22px;
	}


}

.tongyi {
	span {
		height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		line-height: 20px;
	}

	.xieyi {
		color: #1288c8;
		cursor: pointer;

	}
}




</style>